<template >
  <div name="attenance">
    <table
      class="
        w-full
        mx-auto
        mb-2
        h-12
        pt-2
        align-text-bottom
        bg-white
        rounded
        text-left text-gray-400
      "
    >
      <thead class="border-b border-blue-100 border-solid">
        <tr>
          <th width="40%" class="pl-4">Name</th>
          <th class="text-center"></th>
        </tr>
      </thead>
    </table>

    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <table
      v-if="componentLoaded"
      class="table-wrapper w-full bg-white mx-auto rounded shadow-sm"
    >
      <tbody>
        <tr v-for="pupil in pupils" :key="pupil.identifier">
          <td width="40%">{{ pupil.name }}</td>
          <td class="text-right">
            <button
              v-for="item in attendanceValues"
              :key="item.value"
              type="button"
              class="
                border-none
                col-span-1
                px-2
                p-1
                mt-1
                rounded
                lg:mr-4
                xl:mr-5
                text-xs
                font-medium
                hover:text-white
              "
              v-bind:class="setClass(pupil, item)"
              @click="setAttendendance(pupil, item)"
            >
              <i
                class="pr-1"
                v-bind:class="
                  valueExists(pupil, item)
                    ? 'fas fa-check-circle'
                    : 'far fa-dot-circle'
                "
              ></i>
              {{ item.name }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

  <script>
import { mapGetters } from "vuex";
import { SchoolService, BaseService } from "@/services/school.service";
import { calendarYearList } from "@/assets/js/utils";

export default {
  name: "attenance",
  props: {
    lesson: Object,
    pupils: Array,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return true;
    },
  },
  data: function () {
    return {
      errored: false,
      attendances: [],
      attendanceValues: [
        { value: "attended", name: "Attended", color: "bg-green-300" },
        { value: "injured", name: "Injured", color: "bg-red-800" },
        { value: "no-kit", name: "No Kit", color: "bg-orange-600" },
        { value: "absent", name: "Absent", color: "bg-gray-800" },
      ],
    };
  },
  created() {
    this.fetchLessonAttendance();
  },
  methods: {
    valueExists(pupil, type) {
      let pupilResults = this.attendances.filter((a) => a.register_identifier === pupil.identifier)

      if (pupilResults.length === 0 && type.value === 'attended') {
        return true
      } else {
        let result = pupilResults.find((a) =>a.attendance_type === type.value)
        return result != undefined
      }
    },
    setClass(pupil, type) {
      let hover = this.setHoverClass(type);

      if (this.valueExists(pupil, type)) {
        return type.color + " text-white " + hover;
      } else {
        return "bg-gray-300 text-gray-500 " + hover;
      }
    },
    setHoverClass(type) {
      switch (type.value) {
        case "injured":
          return "hover:bg-red-800";
          break;
        case "no-kit":
          return "hover:bg-orange-600";
          break;
        case "absent":
          return "hover:bg-gray-800";
          break;
        default:
          return "hover:bg-green-300";
          break;
      }
    },
    fetchLessonAttendance() {
      SchoolService.getAll("lessons/" + this.lesson.id + "/attendances")
        .then((response) => this.buildAttendances(response.data))
        .catch((err) => {
          console.log("Attendance " + err);
          this.errored = true;
        });
    },
    buildAttendances(data) {
      this.attendances = data;
    },
    setAttendendance(pupil, type) {
      var result = this.attendances.find(
        (a) => a.register_identifier === pupil.identifier
      );

      if (result != undefined) {
        result.attendance_type = type.value;

        SchoolService.update("attendances", result.identifier, result).catch(
          (err) => {
            this.completion(err);
          }
        );
      } else {
        let calendarYear = calendarYearList().find((y) => y.current === true);
        let payload = {
          lesson_id: this.lesson.id,
          attendance_type: type.value,
          register_identifier: pupil.identifier,
          calendar_schoolyear: calendarYear.value,
        };

        SchoolService.create("attendances", payload)
          .then((response) => {
            payload.identifier = response.data.identifier;
            this.attendances.push(payload);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>